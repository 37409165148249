const metrics = {

    "127.0.0.1": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "localhost": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "baksbo.ru": {
        "script": "main",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    },
    "msk.baksbo.ru": {
        "script": "msk",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA.",
    },
    "spb.baksbo.ru": {
        "script": "spb",
        "yandex": "ab658bcdccb5c64f",
        "google": "GwI1V9yQ4AWueYfnFfZrC2kjfIk0RwmF4-x7rhtT5h8",
    },
    "krd.baksbo.ru": {
        "script": "krd",
        "yandex": "e722413efd83819d",
        "google": "GwI1V9yQ4AWueYfnFfZrC2kjfIk0RwmF4-x7rhtT5h8",
    },
    "kzn.baksbo.ru": {
        "script": "kzn",
        "yandex": "84b54a561474a428",
        "google": "ZgYgwxV5nY9I6UA2oN__DVtsR2xnUdv2t4SVEP7ylQA",
    }
}
export default metrics